import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";

const TermsConditions = () => {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="bg-gray-100 py-[7rem]">
        <div className="bg-white text-gray-800 p-6 sm:p-10 max-w-4xl mx-auto rounded-lg shadow-md">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 text-center">
            Terms and Conditions
          </h1>
          <p className="text-base sm:text-lg mb-6">
            Welcome to Kitovu Technology Company! These Terms and Conditions
            ("Terms") govern your access to and use of our website and services.
            By accessing or using our website, you agree to be bound by these
            Terms.
          </p>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              1. Acceptance of Terms
            </h2>
            <p className="mb-4">
              By accessing and using our website, you acknowledge that you have
              read, understood, and agree to be bound by these Terms. Kitovu
              Technology Company may modify these Terms from time to time, and
              we will post any updates on this page. It is your responsibility
              to review the Terms periodically.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              2. Use of Our Website
            </h2>
            <p className="mb-4">
              You agree to use our website and services for lawful purposes only
              and in a manner that does not infringe the rights or restrict the
              use and enjoyment of the site by any third party. Prohibited
              actions include, but are not limited to:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Using the website for fraudulent or harmful activities</li>
              <li>
                Attempting to gain unauthorized access to other systems through
                our website
              </li>
              <li>
                Disrupting the normal operation of the website through viruses,
                malicious software, or excessive requests
              </li>
              <li>
                Engaging in activities that violate local, national, or
                international laws
              </li>
            </ul>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              3. Intellectual Property Rights
            </h2>
            <p className="mb-4">
              All content on this website, including text, images, logos, and
              software, is owned or licensed by Kitovu Technology Company and is
              protected by intellectual property laws. You may not reproduce,
              distribute, or create derivative works from our content without
              prior written permission from Kitovu Technology Company.
            </p>
            <p>
              You may download or print content from our website for personal,
              non-commercial use only, provided that you do not modify it and
              retain all copyright or proprietary notices.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              4. User Content and Submissions
            </h2>
            <p className="mb-4">
              If you submit any content, including feedback, suggestions, or
              other materials, to Kitovu Technology Company, you grant us a
              non-exclusive, royalty-free, worldwide license to use, modify, and
              distribute such content for the purpose of improving our services.
              You represent that any content you submit does not infringe on the
              rights
            </p>
            <p>
              We reserve the right to remove or modify any content posted by
              users on our website if it violates these Terms or is otherwise
              objectionable.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              5. Limitation of Liability
            </h2>
            <p className="mb-4">
              To the fullest extent permitted by law, Kitovu Technology Company,
              its affiliates, and employees are not liable for any direct,
              indirect, incidental, or consequential damages arising out of your
              use of or inability to use our website or services. This includes,
              but is not limited to, damages resulting from loss of data, lost
              profits, or interruption of services.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              6. Disclaimer of Warranties
            </h2>
            <p className="mb-4">
              Our website and services are provided "as is" and "as available"
              without any warranties, express or implied. Kitovu Technology
              Company does not guarantee that the website will be error-free,
              uninterrupted, or free of viruses or harmful components. We
              disclaim all warranties, including those of merchantability,
              fitness for a particular purpose, and non-infringement.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              7. Indemnification
            </h2>
            <p className="mb-4">
              You agree to indemnify and hold Kitovu Technology Company, its
              affiliates, employees, and agents harmless from any claims,
              damages, liabilities, and expenses arising from your use of the
              website, violation of these Terms, or infringement of any
              third-party rights.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              8. Governing Law
            </h2>
            <p className="mb-4">
              These Terms shall be governed by and construed in accordance with
              the laws of Federal Republic of Nigeria, without regard to its
              conflict of law principles. Any dispute arising from these Terms
              or your use of our website shall be subject to the exclusive
              jurisdiction of the courts in Nigeria.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              9. Termination
            </h2>
            <p className="mb-4">
              Kitovu Technology Company reserves the right to suspend or
              terminate your access to our website and services at any time,
              without prior notice, for any violation of these Terms or for
              other reasons that may affect the safety and integrity of our
              services.
            </p>
          </section>
          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              10. Entire Agreement
            </h2>
            <p className="mb-4">
              These Terms, together with our Privacy Policy, constitute the
              entire agreement between you and Kitovu Technology Company
              regarding your use of our website and services, superseding any
              prior agreements.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-xl sm:text-2xl font-semibold text-gray-900 mb-3">
              11. Contact Us
            </h2>
            <p className="mb-4">
              If you have any questions, concerns, or requests regarding these
              Terms, please contact us:
            </p>
            <ul className="list-none space-y-2">
              <li>
                <strong>Email:</strong> &nbsp;
                <a
                  href="mailto:info@kitovu.com.ng"
                  className="text-blue-500 hover:underline"
                >
                  info@kitovu.com.ng
                </a>
              </li>
              <li>
                <strong>Phone:</strong> +2348062148183
              </li>
              <li>
                <strong> Business Address:</strong> Area, Iseyin Area, Iseyi
                Oyo.
              </li>
            </ul>
          </section>
          <p className="text-sm text-gray-600 mt-6">
            Thank you for using Kitovu Technology Company. We are dedicated to
            providing a safe, valuable, and reliable experience on our platform.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
